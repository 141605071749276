.search-box {
    width: 250px;
}

.post p > br {
    display: none
}

@media screen and (max-width: 990px) {
    .search-box {
        width: 100%;
    }
}

.login-box {
    padding: 25px;
    background-color: #ccc;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bd-placeholder-img {
font-size: 1.125rem;
text-anchor: middle;
-webkit-user-select: none;
-moz-user-select: none;
user-select: none;
}

@media (min-width: 768px) {
.bd-placeholder-img-lg {
    font-size: 3.5rem;
}
}

.b-example-divider {
height: 3rem;
background-color: rgba(0, 0, 0, .1);
border: solid rgba(0, 0, 0, .15);
border-width: 1px 0;
box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
flex-shrink: 0;
width: 1.5rem;
height: 100vh;
}

.bi {
vertical-align: -.125em;
fill: currentColor;
}

.nav-scroller {
position: relative;
z-index: 2;
height: 2.75rem;
overflow-y: hidden;
}

.nav-scroller .nav {
display: flex;
flex-wrap: nowrap;
padding-bottom: 1rem;
margin-top: -1px;
overflow-x: auto;
text-align: center;
white-space: nowrap;
-webkit-overflow-scrolling: touch;
}

body {
min-height: 100vh;
min-height: -webkit-fill-available;
}

html {
height: -webkit-fill-available;
}

main {
height: 100vh;
height: -webkit-fill-available;
max-height: 100vh;
overflow-x: auto;
overflow-y: hidden;
}

.dropdown-toggle { outline: 0; }

.btn-toggle {
padding: .25rem .5rem;
font-weight: 600;
color: rgba(0, 0, 0, .65);
background-color: transparent;
}
.btn-toggle:hover,
.btn-toggle:focus {
color: rgba(0, 0, 0, .85);
background-color: #d2f4ea;
}

.btn-toggle::before {
width: 1.25em;
line-height: 0;
content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
transition: transform .35s ease;
transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
color: rgba(0, 0, 0, .85);
}
.btn-toggle[aria-expanded="true"]::before {
transform: rotate(90deg);
}

.btn-toggle-nav a {
padding: .1875rem .5rem;
margin-top: .125rem;
margin-left: 1.25rem;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
background-color: #d2f4ea;
}

.scrollarea {
overflow-y: auto;
}

.btn-block {
    display: block;
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
}

.post-editor-toolbar {
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 10px;
}

.post-editor {
    border: 1px solid black !important;
    padding: 10px !important;
    margin-bottom: 10px !important;
}

.ql-container {
    height: 400px !important;
}

.clearfix {
    clear: both;
}

.post-list-item {
    padding: 25px;
    border: 1px solid #444;
    border-radius: 10px;
}

.post-header {
    margin-bottom: 20px;
}

.post-preview {
    max-height: 68px;
    overflow-y: hidden;
}

.social-media-icons {
    font-size: 45px;
}

.social-media-icons a {
    margin-right: 15px;
}

.post-header a, .post-header a:visited, .post-header a:active, .post-header a:hover, .post-header a:focus {
    color: #444;
    text-decoration:none;
}

.comment {
    padding: 25px;
    border: 1px solid #444;
    border-radius: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.comment-replies {
    margin-left: 50px;
}

.comment-form {
    margin-bottom: 20px;
}

 
.navigationButtons {
	width: 80%;
	height: 40px;
	list-style: none;
	display: flex;
	justify-content: center;
	margin: auto;
	padding-top: 40px;
	margin-bottom: 80px;
}
	
.navigationButtons a {
	padding: 10px;
	margin: 8px;
	border-radius: 5px;
	border: 1px solid black;
	color: black;
	cursor: pointer;
}
	
.navigationButtons a:hover {
	color: white;
	background-color: #444;
}
	
.navigationActive a {
	color: white;
	background-color: #444;
}
	
.navigationDisabled a {
	color: white;
	background-color: grey;
}
  
.post-preview-image {
    border: 1px solid black;
    width: 33%;
    height: 150px;
    float: left;
}

.post-preview-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.post-preview-text {
    width: 63%;
    margin-left: 3%;
    float: left;
}

.post-preview-text-fw {
    width: 100%;
}

.swal2-confirm {
    background-color: #0d6efd !important;
}